module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"1YUGPTC7lf9cEYz7shWSM-bBq4in2yGntz3rFMICQiI","defer":true},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Solmation","short_name":"Solmation","start_url":"/","background_color":"#f7f0eb","theme_color":"#a2466c","display":"standalone","icon":"/opt/build/repo/gatsby.site/src/images/logo.svg","icons":[{"src":"/icons/apple-touch-icon.png","sizes":"192x192","type":"image/png"},{"src":"/icons/apple-touch-icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/icons/apple-touch-icon-114x114.png","sizes":"114x114","type":"image/png"},{"src":"/icons/apple-touch-icon-512x512.png","sizes":"512x512","type":"image/png"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"627318cd4f36791c6a8ff8d1f8ceacec"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"appendScript":"/opt/build/repo/gatsby.site/src/custom-sw-code.js","workboxConfig":{"importWorkboxFrom":"cdn"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
